export const DEFAULT_PRE_GRAZING = 800;
export const MIN_PRE_GRAZING = 800;
export const MAX_PRE_GRAZING = 15000;
export const DEFAULT_POST_GRAZING = 800;
export const MIN_POST_GRAZING = 800;
export const MAX_POST_GRAZING = 3500;
export const STEP = 50;
export const GRAZING_UNIT = "kg/DM";
// LINZ license description
export const ATTRIBUTION =
  '© <a href="//www.linz.govt.nz/linz-copyright">LINZ CC BY 4.0</a> © <a href="//www.linz.govt.nz/data/linz-data/linz-basemaps/data-attribution">Imagery Basemap contributors</a>';
// LDS API key
export const API_KEY = "d01fbtg0aqq4n28j99xd8z0tknx";
