export const checkCoverRange = (cover: number): boolean => {
  const minValue = 1;
  const maxValue = 15000;
  if (cover >= minValue && cover <= maxValue) return true;
  return false;
};

// Round origin number to higher nearest round value
export const roundUpNearest = (originNumber: number, round: number): number =>
  Math.ceil(originNumber / round) * round;
