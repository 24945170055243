/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Row, Col, Button } from "reactstrap";
import "./index.css";
import { MapType } from "src/types/CommonTypes";

import { PrintIcon } from "../Icons/Icons";

import estimatedIcon from "./estimated.svg";
import estimatedDisableIcon from "./estimated-disabled.svg";
import cloudsIcon from "./clouds.svg";
import cloudsDisableIcon from "./clouds-disabled.svg";
import satelliteIcon from "./satellite.png";
import satelliteDisableIcon from "./satellite-disabled.png";

const MapLayerSwitch = ({
  isLayerMenuShowed,
  mapType,
  onChangeLayerState,
  onChangeMapType,
  pdfUrl,
  reportType
}) => {
  const buttonClass = "layer-toggle_button";
  const buttonClassActive = "layer-toggle_button active";
  const isDryMatterShow = mapType === MapType.cover;
  const switchCoverFunction = () => {
    onChangeMapType(MapType.cover);
  };
  const swithCloudFunction = () => {
    onChangeMapType(MapType.cloudShadow);
  };
  const resources =
    reportType === "predicted"
      ? [
          [satelliteDisableIcon, buttonClass, () => {}],
          [cloudsDisableIcon, buttonClass, () => {}],
          [estimatedIcon, buttonClassActive, switchCoverFunction]
        ]
      : [
          [
            satelliteIcon,
            `${isDryMatterShow ? buttonClassActive : buttonClass}`,
            switchCoverFunction
          ],
          [
            cloudsIcon,
            `${isDryMatterShow ? buttonClass : buttonClassActive}`,
            swithCloudFunction
          ],
          [estimatedDisableIcon, buttonClass, () => {}]
        ];

  const [coverBackground, cloudBackground, estimatedBackground] = resources.map(
    resource => ({
      background: `url(${resource[0]})`,
      backgroundSize: "3rem 3rem"
    })
  );

  const [coverClass, cloudClass, estimatedClass] = resources.map(
    resource => resource[1] as string
  );

  const [coverFunction, cloudFunction, estimatedFunction] = resources.map(
    resource => resource[2] as any
  );

  const dryMatter = (
    <Row className="layer-legend">
      <Col lg={12} xs={9}>
        <Row>
          <Col lg={6} xs={6} className="left-label">
            No Pasture
          </Col>
          <Col lg={6} xs={6} className="right-label">
            Abundant Pasture
          </Col>
        </Row>
        <Row className="map-layer-color-palette dry-matter-color-scale" />
      </Col>
      <Col xs={3} style={{ textAlign: "right" }} className="d-block d-lg-none">
        <Button className="layer-print-button" href={pdfUrl} download>
          <Row>
            <Col lg={4} xs={4}>
              <PrintIcon className="print-icon" />
            </Col>
            <Col lg={8} xs={8} className="print-icon-text">
              <span style={{ fontSize: "0.75rem" }}>Print Report</span>
            </Col>
          </Row>
        </Button>
      </Col>
    </Row>
  );

  const cloudShadow = (
    <Row className="layer-legend">
      <Col lg={12} xs={9}>
        <Row>
          <Col lg={6} xs={6} className="left-label">
            Clouds
          </Col>
          <Col lg={6} xs={6} className="right-label">
            No Clouds
          </Col>
        </Row>
        <Row className="map-layer-color-palette cloud-shadow-color-scale" />
      </Col>
      <Col xs={3} style={{ textAlign: "right" }} className="d-block d-lg-none">
        <Button className="layer-print-button" href={pdfUrl} download>
          <Row>
            <Col lg={4} xs={4}>
              <PrintIcon className="print-icon" />
            </Col>
            <Col lg={8} xs={8} className="print-icon-text">
              <span style={{ fontSize: "0.75rem" }}>Print Report</span>
            </Col>
          </Row>
        </Button>
      </Col>
    </Row>
  );

  return (
    <div className="map-layer-switch-container">
      <Row style={isLayerMenuShowed ? {} : { display: "none" }}>
        <div className="layers-menu">
          <h5 className="layers-menu_h5">
            Available map layers
            <div
              className="layers-menu_h5_button close-classic"
              onClick={() => onChangeLayerState(false)}
            />
          </h5>
          <div className="layer-toggle-container">
            <div className="layer-toggle">
              <div
                className={coverClass}
                style={coverBackground}
                onClick={coverFunction}
              />
              <span className="layer-toggle_title">Satellite</span>
            </div>

            <div className="layer-toggle">
              <div
                className={cloudClass}
                style={cloudBackground}
                onClick={cloudFunction}
              />
              <span className="layer-toggle_title">Cloud &amp; Shadows</span>
            </div>

            <div className="layer-toggle">
              <div
                className={estimatedClass}
                style={estimatedBackground}
                onClick={estimatedFunction}
              />
              <span className="layer-toggle_title">Estimated</span>
            </div>
          </div>
        </div>
      </Row>
      {isDryMatterShow ? dryMatter : cloudShadow}
    </div>
  );
};

export default MapLayerSwitch;
