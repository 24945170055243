/* eslint-disable import/extensions, react/no-did-update-set-state */

import React, { Component } from "react";
import * as L from "leaflet";

import "./index.css";
import "./leaflet-tilelayer-subpixel-fix.js";
import { MapType, LatLngTuple } from "../../types/CommonTypes";
import { API_KEY, ATTRIBUTION } from "../../constants";

interface PastureAndCloudMapProps {
  mapTypeSelected: MapType;
  cloudShadowImage: string;
  pastureImage: string;
  bbox: number[];
  activeTab?: string;
  sessionCode: string;
  imageId: string;
}

interface PastureAndCloudMapState {
  reRender: boolean;
}

class PastureAndCloudMap extends Component<
  PastureAndCloudMapProps,
  PastureAndCloudMapState
> {
  private map!: L.Map;
  private printMap!: L.Map;
  private image!: L.ImageOverlay;
  private cropwiseTile!: L.TileLayer;

  constructor(props) {
    super(props);
    this.state = {
      reRender: false
    };
    this.initializeMap = this.initializeMap.bind(this);
    this.initializePrintMap = this.initializePrintMap.bind(this);
    this.drawMap = this.drawMap.bind(this);
  }

  // Initialize map display in digital report
  initializeMap(urlTemplate: string, settings: object, bounds: LatLngTuple[]) {
    this.map = new L.Map("map", {
      crs: L.CRS.EPSG3857,
      worldCopyJump: false,
      zoomControl: false
    });
    const tiles = L.tileLayer(urlTemplate, settings).addTo(this.map);
    this.map.addLayer(tiles);
    this.map.fitBounds(bounds);
  }

  // Initialize map for printing
  initializePrintMap(
    urlTemplate: string,
    settings: object,
    bounds: LatLngTuple[]
  ) {
    this.printMap = new L.Map("print-map", {
      crs: L.CRS.EPSG3857,
      worldCopyJump: false,
      zoomControl: false
    });
    const tilesPrint = L.tileLayer(urlTemplate, settings).addTo(this.printMap);
    this.printMap.addLayer(tilesPrint);
    this.printMap.fitBounds(bounds);
  }

  convertBbox = (bbox: number[]): LatLngTuple[] => {
    const bottomLeft: LatLngTuple = [bbox[1], bbox[0]];
    const topRight: LatLngTuple = [bbox[3], bbox[2]];

    return [bottomLeft, topRight];
  };

  drawMap = (mapSelected: MapType) => {
    // LDS tile URL
    const urlTemplate = `https://basemaps.linz.govt.nz/v1/tiles/aerial/3857/{z}/{x}/{y}.png?api=${API_KEY}`;
    const bounds: LatLngTuple[] = this.convertBbox(this.props.bbox);

    const settings = {
      // Set value of max zoom out and min zoom in to make map looks proper
      maxZoom: 18,
      minZoom: 13,
      continuousWorld: true,
      attribution: ATTRIBUTION
    };
    if (this.map === undefined) {
      this.initializeMap(urlTemplate, settings, bounds);
    } else if (this.state.reRender) {
      this.map.remove();
      this.initializeMap(urlTemplate, settings, bounds);
      // Disable manually re-render
      this.setState({
        reRender: false
      });
    }
    if (
      (this.props.pastureImage && this.props.pastureImage.trim() !== "") ||
      (this.props.cloudShadowImage && this.props.cloudShadowImage.trim() !== "")
    ) {
      const imageUrl =
        mapSelected === MapType.cover
          ? `https://${this.props.pastureImage}`
          : `https://${this.props.cloudShadowImage}`;
      const imageBounds = bounds;

      if (this.image) {
        // Remove old image on layer, always use the new one
        this.map.removeLayer(this.image);
      }

      this.image = L.imageOverlay(imageUrl, imageBounds);
      this.image.addTo(this.map);
    } else {
      // choose different map to display on the report
      const subProduct =
        mapSelected === MapType.cloudShadow
          ? "shadow_corrected_drymatter"
          : "drymatter";
      const imageUrl = `https://api.remote-sensing.cropwise.com/images/${this.props.imageId}/${subProduct}/falsecolor/tiles/{z}/{x}/{y}/png?session_token=${this.props.sessionCode}`;
      if (this.cropwiseTile) {
        // Remove old image on layer, always use the new one
        this.map.removeLayer(this.cropwiseTile);
      }
      this.cropwiseTile = L.tileLayer(imageUrl, settings).addTo(this.map);
    }
  };

  componentDidUpdate(preProps) {
    if (preProps.mapTypeSelected !== this.props.mapTypeSelected) {
      if (this.props.activeTab) this.drawMap(this.props.mapTypeSelected);
    }
    if (this.props.activeTab) {
      if (preProps.activeTab !== this.props.activeTab) {
        // Re-render the component when switch from other tab, only when map display in digital report
        if (this.props.activeTab === "1") {
          this.setState({ reRender: true });
        }
      }
    }
    if (this.state.reRender) {
      // Redraw map after manually re-render, only when map display in digital report
      this.drawMap(this.props.mapTypeSelected);
    }
  }

  componentDidMount() {
    if (this.props.activeTab) this.drawMap(this.props.mapTypeSelected);
  }

  render() {
    return this.props.activeTab ? (
      <div id="map" className="content-container" style={{ width: "100%" }} />
    ) : (
      <div
        id="print-map"
        className="content-container"
        style={{ width: "82em", height: "110em" }}
      />
    );
  }
}

export default PastureAndCloudMap;
