export const coverColorScale = [
  {
    color: '#808080',
    end: 0,
    start: -3000,
  },
  {
    color: '#ff0e00',
    end: 30,
    start: 0,
  },
  {
    color: '#ff1b00',
    end: 120,
    start: 30,
  },
  {
    color: '#ff2900',
    end: 210,
    start: 120,
  },
  {
    color: '#ff3300',
    end: 300,
    start: 210,
  },
  {
    color: '#ff4500',
    end: 390,
    start: 300,
  },
  {
    color: '#ff5300',
    end: 480,
    start: 390,
  },
  {
    color: '#ff6100',
    end: 570,
    start: 480,
  },
  {
    color: '#ff7000',
    end: 660,
    start: 570,
  },
  {
    color: '#ff7e00',
    end: 750,
    start: 660,
  },
  {
    color: '#ff8d00',
    end: 840,
    start: 750,
  },
  {
    color: '#ff9b00',
    end: 930,
    start: 840,
  },
  {
    color: '#ffa900',
    end: 1020,
    start: 930,
  },
  {
    color: '#ffb800',
    end: 1110,
    start: 1020,
  },
  {
    color: '#ffd400',
    end: 1200,
    start: 1110,
  },
  {
    color: '#ffe200',
    end: 1290,
    start: 1200,
  },
  {
    color: '#fff000',
    end: 1380,
    start: 1290,
  },
  {
    color: '#fffe00',
    end: 1470,
    start: 1380,
  },
  {
    color: '#f1f600',
    end: 1560,
    start: 1470,
  },
  {
    color: '#e2ee00',
    end: 1650,
    start: 1560,
  },
  {
    color: '#d4e600',
    end: 1740,
    start: 1650,
  },
  {
    color: '#c6dd00',
    end: 1830,
    start: 1740,
  },
  {
    color: '#b8d500',
    end: 1920,
    start: 1830,
  },
  {
    color: '#aacd00',
    end: 2010,
    start: 1920,
  },
  {
    color: '#9cc400',
    end: 2100,
    start: 2010,
  },
  {
    color: '#8ebc00',
    end: 2190,
    start: 2100,
  },
  {
    color: '#7fb400',
    end: 2280,
    start: 2190,
  },
  {
    color: '#70ab00',
    end: 2370,
    start: 2280,
  },
  {
    color: '#62a300',
    end: 2460,
    start: 2370,
  },
  {
    color: '#549b00',
    end: 2550,
    start: 2460,
  },
  {
    color: '#469200',
    end: 2640,
    start: 2550,
  },
  {
    color: '#388a00',
    end: 2730,
    start: 2640,
  },
  {
    color: '#298200',
    end: 2820,
    start: 2730,
  },
  {
    color: '#1b7800',
    end: 2910,
    start: 2820,
  },
  {
    color: '#0e7000',
    end: 3000,
    start: 2910,
  },
];

export const cloudAndShadowColorScale = [
  { color: '#00c800', end: 0.01, start: 0 }, // uncovered
  { color: '#000000', end: 1, start: 0.01 }, // covered
];

export const awsRegion = 'ap-southeast-2';

// Set a limit for cover value, hide data lower than this value as suggested by commercial
export const COVER_LIMITATION = 850;

export const maxPixel = 600;

// Set 11 as the ticks of feedwedge bar chart, so the steps will be 10
export const TICKS = 11;

// Set round up number to 500
export const ROUND_UP = 500;

// Organization Id provided by CropWise, normally will not change
export const ORG_ID = 'dca0cfdb-61e4-45fe-8043-5ff72ad4921e';
