interface CsvHeader {
  id: string;
  title: string;
}

export const createCsvString = (
  headers: CsvHeader[],
  values: any[]
): string => {
  const headerRow = `${headers.map(header => header.title).join(",")}\n`;
  const rows = values
    .map(val => {
      if (!val) return "";
      return headers.reduce((prev, curr, i) => {
        if (i === 0) return val[curr.id];
        return `${prev},${val[curr.id]}`;
      }, "");
    })
    .join("\n");
  return headerRow + rows;
};
