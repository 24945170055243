import React from "react";
import { Row, Col, Button, Modal, ModalBody } from "reactstrap";

import { LoadingIcon } from "../LoadingIcon/LoadingIcon";
import "./StaticContent.css";
import { CloseModalIcon } from "../Icons/Icons";

import DairyNZ from "./DairyNZ-logo.jpg";
import Niwa from "./niwa-logo.png";
import MPI from "./MPI-logo.jpg";

const Error = () => (
  <Row align="middle" className="static-font">
    <Col>Something went wrong If this problem persists, call LIC.</Col>
  </Row>
);

const Loading = () => (
  <div style={{ marginTop: "10%", marginBottom: "70%" }}>
    <LoadingIcon />
  </div>
);

const NoContent = () => (
  <Row align="middle" className="static-font">
    <Col>No data Found For This Report ID</Col>
  </Row>
);

const NoReportId = () => (
  <Row align="middle" className="static-font">
    <Col>No Report Id Click the link in the email from the SPACE Team</Col>
  </Row>
);

const CommonModal = props => {
  const { className, modal, toggle, type, reportType } = props;
  return (
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalBody>
        <p onClick={toggle} onKeyDown={toggle}>
          <CloseModalIcon className="close-modal-icon" />
        </p>
        {type === "branding" ? (
          <>
            <h5>
              {reportType === "predicted"
                ? "This report is"
                : "This report contains"}
            </h5>
            {reportType === "predicted" ? (
              <h4 className="predicted-heading">FULLY ESTIMATED</h4>
            ) : (
              <h4 className="satellite-heading">SOME PASTURE ESTIMATES</h4>
            )}

            <p className="modal-wording1">
              Pasture Vibe is the next generation of the Pasture Growth
              Forecaster, which was developed with funding from DairyNZ and the
              Ministry of Primary Industries through the Primary Growth
              Partnership to help farmers make better pasture management
              decisions.
            </p>
            <Row className="modal-logo">
              <Col lg={{ size: 4 }} xs={{ size: 4 }}>
                <img src={DairyNZ} alt="DairyNZLogo" />
              </Col>
              <Col lg={{ size: 8 }} xs={{ size: 8 }}>
                <img src={MPI} alt="MPILogo" />
              </Col>
            </Row>
            <p className="modal-wording2">
              DairyNZ’s Pasture Vibe uses NIWA climate data.
            </p>
            <div className="modal-logo1">
              <img src={Niwa} alt="Niwa" />
            </div>
            <Button color="primary" className="report-button" onClick={toggle}>
              VIEW REPORT
            </Button>
          </>
        ) : (
          <>
            <p className="modal-reminder-header">REMINDER</p>
            <p className="modal-reminder-body">
              When you edit covers, you need to know:
            </p>
            <ul className="modal-reminder-list">
              <li>
                The changes won’t be saved. If you exit or refresh the report
                page the changes will no longer be there.
              </li>
              <li>
                To save changes, you need to download the csv file and save it
                once you’ve finished your edits.
              </li>
              <li>
                To update your Land & Feed records, download the updated csv
                file and upload it using the ‘Upload Pasture Covers’ option in
                the Pasture Cover tab of the Land & Feed farm. This will update
                any previous records for that date.
              </li>
              <li>
                Editing the cover values will update both the paddock list
                ranking and the feedwedge. The farm APC will not update, nor
                will the imagery.
              </li>
              <li>
                The print version that is available via the Print Report button
                will not reflect these changes. If you need to print the updated
                paddock list or feedwedge, we recommend printing the updated csv
                file for the paddock list, or taking a screenshot of the updated
                feedwedge and printing this instead.
              </li>
            </ul>
            <Button
              color="primary"
              className="modal-reminder-button"
              onClick={toggle}
            >
              OK
            </Button>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export { Error, Loading, NoContent, NoReportId, CommonModal };
