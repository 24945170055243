import { getConfig } from "./config";

export async function getDataFromAwsApi(reportId: string): Promise<any> {
  try {
    const config = await getConfig();
    const apiUrl = config?.getDataApiUrl as string;
    const showPredictedView = config?.showPredictedView as boolean;

    const body = { report_id: reportId, showPredictedView };

    // Call the api here to get data for a farm
    if (apiUrl) {
      const response = await fetch(apiUrl, {
        method: "post",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" }
      });
      const json = response.json();
      return json;
    } else {
      throw Error("Could not get API url");
    }
  } catch (error) {
    return error;
  }
}
