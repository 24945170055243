import React from "react";
import { Switch, Slider, InputNumber, Badge } from "antd";
import { Row, Col } from "reactstrap";
import { EditedCover } from "src/types/CommonTypes";

import FeedWedgeBarChart from "../FeedWedgeBarChart";
import "./index.css";
import {
  DEFAULT_POST_GRAZING,
  DEFAULT_PRE_GRAZING,
  GRAZING_UNIT,
  MIN_POST_GRAZING,
  MAX_PRE_GRAZING,
  STEP,
  MIN_PRE_GRAZING,
  MAX_POST_GRAZING
} from "../../constants";
import { ReportType } from "../../../../domain/models/ReportType";

interface InteractiveFormProps {
  paddocks: EditedCover[];
  preGrazing: number;
  postGrazing: number;
  maxCover: number;
  showTarget: boolean;
  updateShowTarget: Function;
  updatePreSlide: Function;
  updatePreInput: Function;
  updatePostSlide: Function;
  updatePostInput: Function;
  reportType: ReportType;
  isScrollShow: boolean;
}
export default class InteractiveForm extends React.Component<
  InteractiveFormProps
> {
  render() {
    const {
      preGrazing,
      postGrazing,
      maxCover,
      showTarget,
      updateShowTarget,
      updatePreSlide,
      updatePreInput,
      updatePostSlide,
      updatePostInput,
      reportType,
      isScrollShow,
      paddocks
    } = this.props;

    // Determine whether paddocks contains edited paddock, if true, add a Manually updated cover legend with the same color of edited bar
    const isManuallyUpdatedCoverExist =
      paddocks.filter(paddock => paddock.isEdited).length > 0;

    let legend =
      reportType === "mixed" ? (
        <>
          <Col className="feedWedgeLegendLeft">
            <Badge color="#f2a0ee" text="Estimated pasture cover" />
          </Col>
          <Col className="feedWedgeLegendCenter">
            <Badge color="#007bbc" text="Satellite pasture cover" />
          </Col>
        </>
      ) : reportType === "satellite" ? (
        <Col className="feedWedgeLegendCenter">
          <Badge
            color="#007bbc"
            text="Satellite pasture cover"
            className="feedWedgeLegendLeft"
          />
        </Col>
      ) : (
        <Col className="feedWedgeLegendCenter">
          <Badge color="#f2a0ee" text="Estimated pasture cover" />
        </Col>
      );

    legend = isManuallyUpdatedCoverExist ? (
      <>
        {legend}
        <Col className="feedWedgeLegendRight">
          <Badge color="#203a70" text="Manually updated cover" />
        </Col>
      </>
    ) : (
      legend
    );

    const layout = { xs: "1", sm: "1", md: "3", lg: "3", xl: "3" };
    return (
      <div className="feedWedgeWrapper">
        <div className="feedWedgeLegend">{legend}</div>
        <FeedWedgeBarChart
          postGrazing={postGrazing}
          preGrazing={preGrazing}
          showTarget={showTarget}
          feedWedge={paddocks}
          maxCover={maxCover}
        />
        {isScrollShow && (
          <div className="row-container grazing-target">
            <Row {...layout} className="row-container">
              <Col className="align-description">Add Grazing target:</Col>
              <Col className="align-control">
                <Switch
                  onChange={value => updateShowTarget(value)}
                  style={{ maxWidth: "44px" }}
                />
              </Col>
              <Col />
            </Row>
            <Row
              className={showTarget ? "row-container" : "hidden"}
              {...layout}
            >
              <Col className="align-description">Set Pre-grazing target:</Col>
              <Col className="align-control">
                <Slider
                  max={MAX_PRE_GRAZING}
                  min={MIN_PRE_GRAZING}
                  step={STEP}
                  onChange={value => updatePreSlide(value)}
                  value={
                    typeof preGrazing === "number"
                      ? preGrazing
                      : DEFAULT_PRE_GRAZING
                  }
                />
              </Col>
              <Col className="align-control">
                <InputNumber
                  max={MAX_PRE_GRAZING}
                  min={MIN_PRE_GRAZING}
                  step={STEP}
                  value={preGrazing}
                  onChange={value => updatePreInput(value)}
                />
                <span className="grazing-unit">{GRAZING_UNIT}</span>
              </Col>
            </Row>
            <Row
              className={showTarget ? "row-container" : "hidden"}
              {...layout}
            >
              <Col className="align-description">Set Post-grazing target:</Col>
              <Col className="align-control">
                <Slider
                  min={MIN_POST_GRAZING}
                  max={MAX_POST_GRAZING}
                  step={STEP}
                  onChange={value => updatePostSlide(value)}
                  value={
                    typeof postGrazing === "number"
                      ? postGrazing
                      : DEFAULT_POST_GRAZING
                  }
                />
              </Col>
              <Col className="align-control">
                <InputNumber
                  min={MIN_POST_GRAZING}
                  max={MAX_POST_GRAZING}
                  step={STEP}
                  value={postGrazing}
                  onChange={value => updatePostInput(value)}
                />
                <span className="grazing-unit">{GRAZING_UNIT}</span>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
