export interface Cover {
  id: string;
  paddock_name: string;
  cover: number;
  isPredicted: boolean;
  paddock_id: string;
}

export interface EditedCover extends Cover {
  isEdited: boolean;
}
export interface Configuration {
  getDataApiUrl: string;
  pdfDomain: string;
  showPredictedView: boolean;
}

export enum MapType {
  cover,
  cloudShadow
}

export type LatLngTuple = [number, number];
