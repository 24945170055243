import React from "react";
import isUUID from "validator/es/lib/isUUID";
import { RouteComponentProps } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Navbar,
  Button
} from "reactstrap";
import moment from "moment";
import { getConfig } from "src/utils/config";

import { getDataFromAwsApi } from "../../utils/api";
import { Sidebar, DownloadCSVButton } from "../Sidebar";
import { CoverMap } from "../CoverMap";
import InteractiveForm from "../InteractiveForm";
import {
  Cover,
  EditedCover,
  MapType,
  Configuration
} from "../../types/CommonTypes";
import {
  NoReportId,
  NoContent,
  Error,
  Loading,
  CommonModal
} from "../StaticContent/StaticContent";
import { roundUpNearest } from "../../utils/mathHelpers";
import { createCsvString } from "../../utils/csvHelper";
import { LICLogoIcon, SpaceLogoIcon } from "../Icons/Icons";
import {
  DEFAULT_POST_GRAZING,
  DEFAULT_PRE_GRAZING,
  MAX_PRE_GRAZING,
  MAX_POST_GRAZING
} from "../../constants";
import { COVER_LIMITATION, ROUND_UP } from "../../../../domain/constants";
import { ReportType } from "../../../../domain/models/ReportType";

interface IGetReportProps extends RouteComponentProps<any> {}

interface IGetReportState {
  isErrored: boolean;
  isLoading: boolean;
  farmName: string;
  farmId: string;
  imageId: string;
  pastureImage: string;
  cloudShadow: string;
  dateMeasured: string;
  avgCover: number;
  reportId: string;
  dataFound: boolean;
  paddockInfo: Cover[];
  editedPaddock: EditedCover[];
  reportType: ReportType;
  previousReportDate: string | undefined;
  activeTab: string;
  showOverlay: boolean;
  mapType: MapType;
  bbox: number[];
  csvFileName: string;
  csvData: string;
  preGrazing: number;
  postGrazing: number;
  maxCover: number;
  showTarget: boolean;
  acquisitionDate: string;
  pdfUrl: string;
  showModal: boolean;
  showPredictedView: boolean;
  modalCount: number;
  isScrollShow: boolean;
  sessionCode: string;
}

class GetReport extends React.Component<IGetReportProps, IGetReportState> {
  constructor(props: IGetReportProps) {
    super(props);
    const { id } = this.props.match.params;
    this.state = {
      isErrored: false,
      isLoading: false,
      farmName: "",
      farmId: "",
      imageId: "",
      pastureImage: "",
      cloudShadow: "",
      dateMeasured: "",
      avgCover: 0,
      reportId: id,
      dataFound: false,
      paddockInfo: [],
      editedPaddock: [],
      reportType: "satellite",
      previousReportDate: undefined,
      activeTab: "1",
      showOverlay: false,
      mapType: MapType.cover,
      bbox: [],
      csvFileName: "",
      csvData: "",
      preGrazing: DEFAULT_PRE_GRAZING,
      postGrazing: DEFAULT_POST_GRAZING,
      maxCover: 0,
      showTarget: false,
      acquisitionDate: "",
      pdfUrl: "",
      showModal: false,
      showPredictedView: false,
      modalCount: 0,
      isScrollShow: true,
      sessionCode: ""
    };
  }

  public async componentDidMount() {
    const { reportId } = this.state;
    const config = await getConfig();
    this.setState({ showPredictedView: config.showPredictedView });
    if (reportId) {
      window.addEventListener("resize", this.toggleOnResize);
      await this.getReportData(reportId, config);
    }
  }

  public render() {
    return this.renderContent();
  }

  private toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  private sortPaddock = (leftPaddock: Cover, rightPaddock: Cover) => {
    return rightPaddock.cover - leftPaddock.cover;
  };

  private toggleOnResize = () => {
    const element = document.getElementsByClassName(
      "paddock-tab"
    )[0] as HTMLElement;

    // Check if element is display: none, so change activeTab state
    if (element.offsetParent === null) {
      if (this.state.activeTab === "3") {
        this.setState({ activeTab: "1" });
      }
    }
  };

  private changeMapType = (mapType: MapType) => {
    this.setState({
      mapType
    });
  };

  private getReportData = async (reportId: string, config: Configuration) => {
    this.setState({
      isLoading: true
    });

    try {
      const response = await getDataFromAwsApi(reportId);
      const result = response.body;

      if (
        result &&
        result.farmLevelReport &&
        result.farmName &&
        result.paddockLevelReports &&
        result.reportType &&
        config
      ) {
        const filterDataForCSV = this.getCSVData(
          result.paddockLevelReports,
          result.farmLevelReport.date_measured
        );
        // get pdf download url
        const pdfDomain = config.pdfDomain;
        const dateInName = moment(result.farmLevelReport.date_measured).format(
          "DDMMYYYY"
        );
        const dateInPath = moment(result.farmLevelReport.date_measured).format(
          "YYYYMMDD"
        );
        const pdfUrl = `${pdfDomain}/${result.farmLevelReport.farm_id}/${dateInPath}/${result.farmLevelReport.image_id}/space_report_${result.farmName}_${dateInName}.pdf`;

        const displayPaddocks = result.paddockLevelReports.filter(
          item => item && item.cover > COVER_LIMITATION
        );

        const maxCover = roundUpNearest(
          Math.max(...displayPaddocks.map(item => item.cover)),
          ROUND_UP
        );
        this.setState({
          isLoading: false,
          farmName: result.farmName,
          farmId: result.farmLevelReport.farm_id,
          imageId: result.farmLevelReport.image_id,
          pastureImage: result.farmLevelReport.cover_map_url,
          dateMeasured: result.farmLevelReport.date_measured,
          avgCover: result.farmLevelReport.total_cover,
          bbox: JSON.parse(result.farmLevelReport.bbox),
          cloudShadow: result.farmLevelReport.cloud_shadow_map_url,
          dataFound: true,
          paddockInfo: result.paddockLevelReports,
          editedPaddock: result.paddockLevelReports.map(
            paddockLevelReport =>
              ({ ...paddockLevelReport, isEdited: false } as EditedCover)
          ),
          reportType: result.reportType,
          previousReportDate: result.previousReportDate
            ? moment(result.previousReportDate).format("DD/MM/YY")
            : undefined,
          csvData: createCsvString(
            [
              { id: "Paddock", title: "Paddock" },
              { id: "Cover", title: "Cover" },
              { id: "DateMeasured", title: "Date Measured" }
            ],
            filterDataForCSV
          ),
          csvFileName: `${result.farmLevelReport.date_measured}-${result.farmName}.csv`,
          acquisitionDate: result.farmLevelReport.acquisition_date,
          pdfUrl,
          isScrollShow: displayPaddocks.length > 0,
          maxCover,
          sessionCode: result.sessionCode
        });
        // Show modal only for Predicted reports for now
        if (this.state.reportType !== "satellite") {
          this.setState({ showModal: true });
        }
        return;
      } else {
        this.setState({
          isLoading: false,
          showModal: false
        });
      }
    } catch (err) {
      console.error(`Error Loading Report Data: ${err}`);
      this.setState({ isLoading: false, isErrored: true });
    }
  };

  private getCSVData = (paddockInfo, dateMeasured) => {
    const filterDataForCSV = paddockInfo.filter(paddock => {
      return paddock.cover >= COVER_LIMITATION || paddock?.isEdited;
    });

    const inputDataForCSV = filterDataForCSV
      .sort(this.sortPaddock)
      .map(paddock => ({
        Paddock: paddock.paddock_name,
        Cover: paddock.cover,
        DateMeasured: dateMeasured
      }));

    return inputDataForCSV;
  };

  private updatePostInput = (value: number | undefined): void => {
    if (value! > this.state.maxCover && value! <= MAX_POST_GRAZING) {
      this.setState({
        maxCover: value as number,
        postGrazing: value as number
      });
    } else {
      this.setState({
        postGrazing: value as number
      });
    }
  };

  private updatePreInput = (value: number | undefined): void => {
    if (value! > this.state.maxCover && value! <= MAX_PRE_GRAZING) {
      this.setState({
        maxCover: value as number,
        preGrazing: value as number
      });
    } else {
      this.setState({
        preGrazing: value as number
      });
    }
  };

  private updateShowTarget = (checked: boolean): void => {
    this.setState({ showTarget: checked });
  };

  private increaseModalCount = () => {
    this.setState({ modalCount: this.state.modalCount + 1 });
  };

  private updatePaddock = (editedPaddock: EditedCover[]) => {
    const displayPaddocks = editedPaddock.filter(
      item => item && item.cover > COVER_LIMITATION
    );

    const maxCover = roundUpNearest(
      Math.max(...displayPaddocks.map(item => item.cover)),
      ROUND_UP
    );

    if (maxCover < Math.max(this.state.preGrazing, this.state.postGrazing)) {
      this.setState({
        preGrazing: DEFAULT_PRE_GRAZING,
        postGrazing: DEFAULT_POST_GRAZING
      });
    }

    this.setState({ editedPaddock, maxCover });
    const filterDataForCSV = this.getCSVData(
      editedPaddock,
      this.state.dateMeasured
    );
    this.setState({
      csvData: createCsvString(
        [
          { id: "Paddock", title: "Paddock" },
          { id: "Cover", title: "Cover" },
          { id: "DateMeasured", title: "Date Measured" }
        ],
        filterDataForCSV
      )
    });
  };

  private renderContent = () => {
    const {
      isErrored,
      isLoading,
      farmName,
      pastureImage,
      cloudShadow,
      bbox,
      avgCover,
      editedPaddock,
      reportId,
      dataFound,
      reportType,
      previousReportDate,
      activeTab,
      csvData,
      csvFileName,
      mapType,
      preGrazing,
      postGrazing,
      maxCover,
      showTarget,
      acquisitionDate,
      pdfUrl,
      showModal,
      showPredictedView,
      modalCount,
      isScrollShow,
      sessionCode,
      imageId
    } = this.state;

    let roundCover = 0;
    let formatDateBold = "";
    let formatDate = "-";
    let nameOfFarm = "-";
    let content;
    let modalContent;
    const tabContent = (
      <TabContent activeTab={activeTab} className="tab-data">
        <TabPane tabId="1">
          <CoverMap
            pastureImage={pastureImage}
            cloudShadow={cloudShadow}
            bbox={bbox}
            activeTab={activeTab}
            mapType={mapType}
            changeMapType={this.changeMapType}
            pdfUrl={pdfUrl}
            reportType={reportType}
            sessionCode={sessionCode}
            imageId={imageId}
          />
        </TabPane>
        <TabPane tabId="2">
          <InteractiveForm
            paddocks={editedPaddock}
            preGrazing={preGrazing}
            postGrazing={postGrazing}
            maxCover={maxCover}
            isScrollShow={isScrollShow}
            showTarget={showTarget}
            updatePostInput={this.updatePostInput}
            updatePostSlide={this.updatePostInput}
            updatePreInput={this.updatePreInput}
            updatePreSlide={this.updatePreInput}
            updateShowTarget={this.updateShowTarget}
            reportType={reportType}
          />
        </TabPane>
        <TabPane tabId="3" className="paddock-tab d-lg-none">
          <Sidebar
            paddocks={editedPaddock}
            csvData={csvData}
            csvFileName={csvFileName}
            showPredictedView={showPredictedView}
            modalCount={modalCount}
            increaseModalCount={this.increaseModalCount}
            updatePaddock={this.updatePaddock}
          />
        </TabPane>
      </TabContent>
    );

    // If no uuid provided with url or wrong uuid provided then return error message
    if (!reportId || !isUUID(reportId)) {
      content = <NoReportId />;
    }
    // If an Error occurred during loading, display the Error screen or If the Request to get
    // report data completed without returning anything, display the NoContent screen.
    else if (isLoading || isErrored || !dataFound) {
      content = isLoading ? <Loading /> : isErrored ? <Error /> : <NoContent />;
    }
    // Return the Report Content.
    else {
      roundCover = Math.round(avgCover);
      // Date got from DB is NZST, remove the last Z of date string otherwise moment will treat it as a UTC time
      const dateWithoutTimeZone = acquisitionDate.replace("Z", "");
      formatDate = moment(dateWithoutTimeZone).format("MMM, LT");
      formatDateBold = moment(dateWithoutTimeZone).format("ddd Do ");
      nameOfFarm = farmName;
      content = tabContent;
      modalContent = showModal ? (
        <CommonModal
          className="onload-branding-modal"
          modal={showModal}
          toggle={this.toggleModal}
          type="branding"
          reportType={reportType}
        />
      ) : (
        ""
      );
    }

    return (
      <>
        <div className="print-container">
          <div className="branding row">
            <Navbar color="faded" light>
              <Col
                lg={{ size: 8, order: 1 }}
                md={{ size: 6, order: 1 }}
                sm={{ size: 6, order: 1 }}
                xs={{ size: 6, order: 1 }}
              >
                <LICLogoIcon className="logo_image" />
              </Col>
              <Col
                lg={{ size: 3, order: 2 }}
                md={{ size: 4, order: 2 }}
                sm={{ size: 4, order: 2 }}
                xs={{ size: 6, order: 2 }}
              >
                <SpaceLogoIcon className="space_image" />
              </Col>
            </Navbar>
          </div>
          <Row className="header row justify-content-between">
            <Col
              lg={{ size: 3, order: 1 }}
              md={{ size: 8, order: 1 }}
              sm={{ size: 8, order: 1 }}
              xs={{ size: 8, order: 1 }}
              className="name-heading"
            >
              <strong>{formatDateBold}</strong>
              {formatDate}
            </Col>
            <Col
              lg={{ size: 5, order: 2 }}
              md={{ size: 8, order: 3 }}
              sm={{ size: 8, order: 3 }}
              xs={{ size: 8, order: 3 }}
              className="name-heading farm-name"
            >
              {nameOfFarm.toUpperCase()}
            </Col>
            <Col
              lg={{ size: 3, order: 4 }}
              md={{ size: 4, order: 2 }}
              sm={{ size: 4, order: 2 }}
              xs={{ size: 4, order: 2 }}
              className="d-block d-lg-none"
            >
              {previousReportDate ? (
                <div className="average-heading" style={{ float: "right" }}>
                  <strong>Est. Report</strong>
                </div>
              ) : (
                <div className="average-heading" style={{ float: "right" }}>
                  Avg: <strong>{roundCover}</strong>
                </div>
              )}
            </Col>
            <Col
              lg={{ size: 3, order: 4 }}
              md={{ size: 4, order: 4 }}
              sm={{ size: 4, order: 4 }}
              xs={{ size: 4, order: 4 }}
              className="d-block d-lg-none"
            >
              {previousReportDate ? (
                <div
                  className="average-heading"
                  style={{ float: "right", fontSize: "0.8rem" }}
                >
                  APC Unavailable
                </div>
              ) : (
                <div
                  className="average-heading"
                  style={{ float: "right", fontSize: "0.875rem" }}
                >
                  KG DM/ha
                </div>
              )}
            </Col>
            <Col
              lg={{ size: 4, order: 3 }}
              md={{ size: 5, order: 3 }}
              sm={{ size: 3, order: 3 }}
              xs={{ size: 6, order: 4 }}
              className="average-heading d-none d-lg-block"
            >
              {previousReportDate ? (
                <p style={{ float: "right" }}>
                  Estimates based on <strong>{previousReportDate} </strong>
                  report
                </p>
              ) : (
                <p style={{ float: "right" }}>
                  Average pasture cover: <strong>{roundCover} </strong>KG DM/ha
                </p>
              )}
            </Col>
          </Row>
        </div>
        <Container className="app-container h-100">
          <div className="branding row mobile-footer-fix mobile-row-margin">
            <Navbar color="faded" light>
              <Col
                lg={{ size: 6, order: 1 }}
                md={{ size: 6, order: 1 }}
                sm={{ size: 6, order: 1 }}
                xs={{ size: 6, order: 1 }}
              >
                <LICLogoIcon className="logo_image" />
              </Col>
              <Col
                lg={{ size: 6, order: 2 }}
                md={{ size: 6, order: 2 }}
                sm={{ size: 6, order: 2 }}
                xs={{ size: 6, order: 2 }}
                className="branding-align-right"
              >
                <SpaceLogoIcon className="space_image" />
                <DownloadCSVButton
                  className="branding-csv-button d-none d-lg-inline-block"
                  csvData={csvData}
                  csvFileName={csvFileName}
                />
                <Button
                  className="branding-print-button d-none d-lg-inline-block"
                  disabled={isLoading}
                >
                  <a href={pdfUrl} download>
                    <span style={{ color: "#FFF" }}>PRINT REPORT</span>
                  </a>
                </Button>
              </Col>
            </Navbar>
          </div>
          <div className="mobile-header-fix">
            <Row className="header row justify-content-between mobile-row-margin">
              <Col
                lg={{ size: 3, order: 1 }}
                md={{ size: 8, order: 1 }}
                sm={{ size: 8, order: 1 }}
                xs={{ size: 8, order: 1 }}
                className="name-heading"
              >
                <strong>{formatDateBold}</strong>
                {formatDate}
              </Col>
              <Col
                lg={{ size: 5, order: 2 }}
                md={{ size: 8, order: 3 }}
                sm={{ size: 8, order: 3 }}
                xs={{ size: 8, order: 3 }}
                className="name-heading farm-name"
              >
                {nameOfFarm.toUpperCase()}
              </Col>
              <Col
                lg={{ size: 3, order: 4 }}
                md={{ size: 4, order: 2 }}
                sm={{ size: 4, order: 2 }}
                xs={{ size: 4, order: 2 }}
                className="d-block d-lg-none"
              >
                {previousReportDate ? (
                  <div className="average-heading" style={{ float: "right" }}>
                    <strong>Est. Report</strong>
                  </div>
                ) : (
                  <div className="average-heading" style={{ float: "right" }}>
                    Avg: <strong>{roundCover}</strong>
                  </div>
                )}
              </Col>
              <Col
                lg={{ size: 3, order: 4 }}
                md={{ size: 4, order: 4 }}
                sm={{ size: 4, order: 4 }}
                xs={{ size: 4, order: 4 }}
                className="d-block d-lg-none"
              >
                {previousReportDate ? (
                  <div
                    className="average-heading"
                    style={{ float: "right", fontSize: "0.8rem" }}
                  >
                    APC Unavailable
                  </div>
                ) : (
                  <div
                    className="average-heading"
                    style={{ float: "right", fontSize: "0.875rem" }}
                  >
                    KG DM/ha
                  </div>
                )}
              </Col>
              <Col
                lg={{ size: 4, order: 3 }}
                md={{ size: 5, order: 3 }}
                sm={{ size: 3, order: 3 }}
                xs={{ size: 6, order: 4 }}
                className="average-heading d-none d-lg-block"
              >
                {previousReportDate ? (
                  <p style={{ float: "right" }}>
                    Estimates based on <strong>{previousReportDate} </strong>
                    report
                  </p>
                ) : (
                  <p style={{ float: "right" }}>
                    Average pasture cover: <strong>{roundCover} </strong>KG
                    DM/ha
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 3 }} className="d-none d-lg-block paddock-col">
                <Sidebar
                  paddocks={editedPaddock}
                  csvData={csvData}
                  csvFileName={csvFileName}
                  showPredictedView={showPredictedView}
                  modalCount={modalCount}
                  increaseModalCount={this.increaseModalCount}
                  updatePaddock={this.updatePaddock}
                />
              </Col>
              <Col lg={{ size: 9 }} className="map-column">
                <Nav tabs>
                  <NavItem className="tab-list-items">
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => this.setState({ activeTab: "1" })}
                    >
                      Maps
                    </NavLink>
                  </NavItem>
                  <NavItem className="tab-list-items">
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => this.setState({ activeTab: "2" })}
                    >
                      Feed Wedge
                    </NavLink>
                  </NavItem>
                  <NavItem className="paddock-tab tab-list-items d-lg-none">
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => this.setState({ activeTab: "3" })}
                    >
                      Paddocks
                    </NavLink>
                  </NavItem>
                </Nav>
                {modalContent}
                {content}
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  };
}

export default GetReport;
