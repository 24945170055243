import React from "react";
import { Route, withRouter } from "react-router-dom";

import "./App.css";
import "./admin.css";
import "./print.css";
import GetReport from "./components/GetReport";

function App() {
  // detect if we have an iframe present.
  // This will allow us to remove navigation elements via CSS
  if (window.self !== window.top) {
    document.body.id = "iframe";
  }

  return (
    <div>
      <Route exact path="/" component={GetReport} />
      <Route path="/:id" component={GetReport} />
    </div>
  );
}

export default withRouter(App);
