import * as React from "react";

const LICLogoIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 97.95 27.31"
  >
    <defs>
      <style>{`.cls-1{fill:#0095d6;}.cls-2{fill:#003473;}`}</style>
    </defs>
    <title>Artboard 1</title>
    <path
      className="cls-1"
      d="M29.74,22.63a3.15,3.15,0,0,1,.41,1.57,3.09,3.09,0,0,1-3.06,3.12H19.37a10.28,10.28,0,0,1-1.44-.1A9.55,9.55,0,0,1,10,20a8.68,8.68,0,0,0,15.73-3.9l0-.35Z"
    />
    <path
      className="cls-1"
      d="M12.4,1.56A3.09,3.09,0,0,1,13.53.42a3,3,0,0,1,4.19,1.13l3.86,6.77a10.35,10.35,0,0,1,.64,1.31A9.72,9.72,0,0,1,20,20.21a8.93,8.93,0,0,0,.15-7A8.68,8.68,0,0,0,8.77,8.34l-.32.14Z"
    />
    <path
      className="cls-1"
      d="M3.08,27.3a3,3,0,0,1-1.54-.42A3.13,3.13,0,0,1,.41,22.64l3.86-6.78a10.54,10.54,0,0,1,.8-1.21,9.42,9.42,0,0,1,10.16-3.31,8.66,8.66,0,0,0-6,3.36,8.92,8.92,0,0,0,1.5,12.41l.28.21Z"
    />
    <path
      className="cls-2"
      d="M91.23,8.57H81.86c-1.2,0-1.75.45-2,1.39l-3,12.12c-.24.94.1,1.39,1.3,1.39h9.38l-.89,3.69h-14c-3.74,0-4.88-2.3-3.92-6.17l2.44-10c1-3.87,3.23-6.17,7-6.17h14Z"
    />
    <polyline
      className="cls-2"
      points="70.27 4.88 61.89 4.88 56.39 27.18 64.77 27.18"
    />
    <polyline
      className="cls-2"
      points="54.77 23.48 44.12 23.48 48.72 4.88 40.34 4.88 34.84 27.17 53.88 27.17"
    />
    <path
      className="cls-2"
      d="M95.65,9a2.3,2.3,0,1,1,2.3-2.3A2.3,2.3,0,0,1,95.65,9m0-4.09a1.73,1.73,0,0,0-1.75,1.79,1.75,1.75,0,1,0,3.51,0,1.73,1.73,0,0,0-1.76-1.79m.41,3-.44-.95h-.26v.95h-.55V5.43h1a.78.78,0,0,1,.81.77.69.69,0,0,1-.45.65l.52,1Zm-.32-2h-.38v.64h.38a.32.32,0,1,0,0-.64"
    />
  </svg>
);

const SpaceLogoIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    id="Group_1032"
    data-name="Group 1032"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 61.277 13.555"
  >
    <g id="sapce-logo" transform="translate(0 1)">
      <path
        id="Path_1237"
        data-name="Path 1237"
        d="M14.434,18.079a3.719,3.719,0,0,0,.823-.088,2.031,2.031,0,0,0,.676-.265,1.361,1.361,0,0,0,.47-.441,1.087,1.087,0,0,0,.176-.617,1.185,1.185,0,0,0-.176-.647,1.741,1.741,0,0,0-.529-.5,4.078,4.078,0,0,0-.941-.412c-.382-.118-.882-.265-1.441-.412a10.387,10.387,0,0,1-1.353-.441,4.715,4.715,0,0,1-1.117-.676,2.535,2.535,0,0,1-.735-1,3.359,3.359,0,0,1-.265-1.411,3.119,3.119,0,0,1,1.294-2.558A4.327,4.327,0,0,1,12.7,7.935,6.236,6.236,0,0,1,14.375,7.7a5.8,5.8,0,0,1,1.882.265,6,6,0,0,1,1.382.647,1.681,1.681,0,0,1,.559.559,1.235,1.235,0,0,1,.176.617.841.841,0,0,1-.118.5.719.719,0,0,1-.265.323.994.994,0,0,1-.353.176,1.476,1.476,0,0,1-.353.059,1.172,1.172,0,0,1-.5-.088,1.8,1.8,0,0,1-.412-.206c-.118-.059-.235-.147-.382-.235-.118-.088-.294-.147-.441-.235-.176-.059-.353-.118-.588-.176a3.114,3.114,0,0,0-.794-.059,2.16,2.16,0,0,0-1.441.412,1.171,1.171,0,0,0-.441.882,1.121,1.121,0,0,0,.706,1.029,8.71,8.71,0,0,0,2.235.764c.441.118.882.265,1.323.412a5.034,5.034,0,0,1,1.176.647,3.146,3.146,0,0,1,.853,1,3.364,3.364,0,0,1,.323,1.529,3.285,3.285,0,0,1-.294,1.411,3.748,3.748,0,0,1-.882,1.176,4.646,4.646,0,0,1-1.47.794,6.143,6.143,0,0,1-2.029.294,6.332,6.332,0,0,1-1.323-.118,5.273,5.273,0,0,1-1.088-.323,3.324,3.324,0,0,1-.853-.441,6.19,6.19,0,0,1-.647-.5,3.361,3.361,0,0,1-.412-.47,1.094,1.094,0,0,1-.206-.588,1.007,1.007,0,0,1,.118-.529,1.177,1.177,0,0,1,.294-.353.994.994,0,0,1,.353-.176,1.294,1.294,0,0,1,.294-.059,1.219,1.219,0,0,1,.47.088,2.938,2.938,0,0,1,.412.235,6.459,6.459,0,0,0,.529.412,2.115,2.115,0,0,0,.617.353,5.376,5.376,0,0,0,.735.235A2.977,2.977,0,0,0,14.434,18.079Z"
        transform="translate(-9.7 -7.7)"
        fill="#203a70"
      />
      <path
        id="Path_1238"
        data-name="Path 1238"
        d="M52.54,8.4a5.381,5.381,0,0,1,1.882.294,4.163,4.163,0,0,1,1.441.853,3.551,3.551,0,0,1,.911,1.323,4.893,4.893,0,0,1,0,3.5,3.3,3.3,0,0,1-.941,1.323,3.983,3.983,0,0,1-1.47.823,6.511,6.511,0,0,1-1.911.294H50.305v2.646a1.086,1.086,0,0,1-.323.794,1.089,1.089,0,0,1-1.882-.794V9.517a1.014,1.014,0,0,1,1.088-1.088H52.54Zm-.088,6.469a2.387,2.387,0,0,0,1.764-.617A2.3,2.3,0,0,0,54.8,12.6a2.176,2.176,0,0,0-.617-1.676,2.5,2.5,0,0,0-1.764-.588H50.335V14.81h2.117Z"
        transform="translate(-36.809 -8.194)"
        fill="#203a70"
      />
      <path
        id="Path_1239"
        data-name="Path 1239"
        d="M87.368,17.838H82.34L81.517,19.9a.985.985,0,0,1-.412.529,1.367,1.367,0,0,1-.617.147,1.177,1.177,0,0,1-.794-.265A.872.872,0,0,1,79.4,19.6a1.431,1.431,0,0,1,.029-.353c.029-.118.088-.235.147-.412L83.6,9.37a1.8,1.8,0,0,1,.5-.706,1.152,1.152,0,0,1,.764-.265,1.177,1.177,0,0,1,.794.265,1.8,1.8,0,0,1,.5.706l4.028,9.468a1.769,1.769,0,0,1,.176.676,1.086,1.086,0,0,1-.323.794,1.182,1.182,0,0,1-.823.294.9.9,0,0,1-.647-.206,1.569,1.569,0,0,1-.441-.647Zm-4.322-1.823h3.587l-1.764-4.557Z"
        transform="translate(-58.907 -8.194)"
        fill="#203a70"
      />
      <path
        id="Path_1240"
        data-name="Path 1240"
        d="M129.033,11.522c-.206-.235-.412-.441-.588-.647a3.464,3.464,0,0,0-.647-.5,2.224,2.224,0,0,0-.764-.323,3.129,3.129,0,0,0-.97-.118,3.326,3.326,0,0,0-1.441.294,3.052,3.052,0,0,0-1.088.853,3.518,3.518,0,0,0-.676,1.323,6.073,6.073,0,0,0-.235,1.676,5.861,5.861,0,0,0,.235,1.647,4.036,4.036,0,0,0,.706,1.264,3.027,3.027,0,0,0,1.147.823,3.639,3.639,0,0,0,1.529.294,3.065,3.065,0,0,0,1.617-.382,4.868,4.868,0,0,0,1.264-1.117,1,1,0,0,1,.735-.323.836.836,0,0,1,.676.323,1.1,1.1,0,0,1,.265.676,2.271,2.271,0,0,1-.059.441,1.9,1.9,0,0,1-.265.47,6.683,6.683,0,0,1-.647.647,4.842,4.842,0,0,1-.941.676,5.641,5.641,0,0,1-1.264.529,5.461,5.461,0,0,1-1.588.206,5.946,5.946,0,0,1-2.323-.441,5.219,5.219,0,0,1-1.823-1.264,5.762,5.762,0,0,1-1.176-1.97,8.154,8.154,0,0,1,0-5.116,5.9,5.9,0,0,1,1.176-2,5.415,5.415,0,0,1,1.823-1.294,5.955,5.955,0,0,1,2.323-.47,6.567,6.567,0,0,1,1.764.235,6.892,6.892,0,0,1,1.323.588,4.91,4.91,0,0,1,.911.706,3.538,3.538,0,0,1,.5.588,1.639,1.639,0,0,1,.206.47,1.143,1.143,0,0,1,.059.441,1.219,1.219,0,0,1-.088.47.719.719,0,0,1-.265.323,2.194,2.194,0,0,1-.353.206,1.476,1.476,0,0,1-.353.059,2.307,2.307,0,0,1-.412-.059A.868.868,0,0,1,129.033,11.522Z"
        transform="translate(-87.781 -7.7)"
        fill="#203a70"
      />
      <path
        id="Path_1241"
        data-name="Path 1241"
        d="M170.956,18.7a.978.978,0,0,1,.735.265.954.954,0,0,1,.265.706.917.917,0,0,1-1,1h-6.439a1.089,1.089,0,0,1-.823-.294,1.064,1.064,0,0,1-.294-.794v-10a1.064,1.064,0,0,1,.294-.794,1.116,1.116,0,0,1,.853-.294h6.38a1.1,1.1,0,0,1,.764.265.994.994,0,0,1,.294.735.933.933,0,0,1-.294.735,1.051,1.051,0,0,1-.764.265h-5.292v3.028h3.381a1.177,1.177,0,0,1,.794.265.872.872,0,0,1,.294.706,1.044,1.044,0,0,1-.294.735,1.177,1.177,0,0,1-.794.265h-3.381v3.234h5.322Z"
        transform="translate(-118.209 -8.265)"
        fill="#203a70"
      />
    </g>
    <text
      id="TM"
      transform="translate(55.277 4)"
      fill="#203a70"
      fontSize="4"
      fontFamily="BoosterNextFY-Black, Booster Next FY"
      fontWeight="800"
    >
      <tspan x="0" y="0">
        TM
      </tspan>
    </text>
  </svg>
);

const SearchIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 96 96"
  >
    <style type="text/css">{`.st0{fill:#1F3B70;}`}</style>
    <path
      className="st0"
      d="M94.5,81.8c-0.1-0.1-0.1-0.1-0.2-0.1L68.7,56.1c10.5-17.4,4.9-40-12.4-50.4C38.9-4.8,16.3,0.7,5.9,18.1
	C-4.6,35.5,1,58.1,18.3,68.6c11.7,7.1,26.3,7.1,37.9,0l25.6,25.6c2,2,5.3,2,7.4,0c0,0,0,0,0,0l5-5.1C96.3,87.1,96.5,83.8,94.5,81.8z
	 M52.7,52.5c-8.5,8.5-22.3,8.5-30.8,0c-8.5-8.5-8.5-22.3,0-30.9s22.3-8.5,30.8,0S61.2,44,52.7,52.5z"
    />
  </svg>
);

const LayersIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 96 96"
  >
    <style type="text/css">{`.st0{fill:#21275D;}`}</style>
    <path
      id="Path_1260"
      className="st0"
      d="M75.6,37.1l20-9.7L48.4,4.9l-48,22.4l20,9.9l-20,9.3l20.2,10L0.4,66l47.1,23.3l48.1-23.3
	l-20.3-9.6l20.3-9.8L75.6,37.1z M83.6,66.1l-36,17.5L12.4,66.2l14.2-6.6l21,10.4l21.8-10.6L83.6,66.1z M69.3,53.6l-3,1.5l-3,1.5
	l-15.8,7.7l-15-7.4l-3-1.5l-3-1.5l-14.3-7l13.9-6.5l14.3,7l3,1.5l3,1.5l1,0.5l1.8-0.9l3-1.5l3-1.5L69.6,40l14,6.7L69.3,53.6z"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    height="40px"
  >
    <defs>
      <style>{`.cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.7px;`}</style>
    </defs>
    <title>Close</title>
    <line className="cls-1" x1="20.79" y1="11.19" x2="11.21" y2="20.81" />
    <line className="cls-1" x1="20.79" y1="20.81" x2="11.21" y2="11.19" />
  </svg>
);

const DownloadIcon = (props: { className: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    viewBox="0 0 21.999 22"
  >
    <defs>
      <style type="text/css">{".a{fill:#FFF;}"}</style>
    </defs>
    <g transform="translate(-355 -133)">
      <path
        className="a"
        d="M22,15.047a1,1,0,0,1,1,1v4a2.987,2.987,0,0,1-2.976,3H4a2.988,2.988,0,0,1-3-3v-4a.963.963,0,0,1,.293-.707A.963.963,0,0,1,2,15.047a.963.963,0,0,1,.707.293A.963.963,0,0,1,3,16.047v4a1,1,0,0,0,1,1H20.024a.928.928,0,0,0,.691-.293A.977.977,0,0,0,21,20.047v-4a1,1,0,0,1,1-1Z"
        transform="translate(353.999 131.953)"
      />
      <path
        className="a"
        d="M12,1.047a.966.966,0,0,1,.711.289l5,5a1.006,1.006,0,0,1-1.422,1.422L13,4.461V16.047a1,1,0,0,1-2,0V4.461l-3.289,3.3A1,1,0,0,1,7,8.047a1,1,0,0,1-1-1,1,1,0,0,1,.289-.711l5-5A.966.966,0,0,1,12,1.047Z"
        transform="translate(377.999 150.047) rotate(180)"
      />
    </g>
  </svg>
);

const SatelliteIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 612 612"
  >
    <defs>
      <style type="text/css">{".st0{fill:#21285D;}"}</style>
    </defs>
    <path
      className="st0"
      d="M182.9,182.8L182.9,182.8c-20.2,20.2-22.4,51.7-6.5,74.4l80.9-80.9C234.6,160.4,203.1,162.5,182.9,182.8z"
    />
    <path
      className="st0"
      d="M483.1,11L309.7,184.4c-3.3,3.3-3.6,9.5,0,13.1l26.6,26.6l-16.1,16.1l-50.8-51.5l-80.2,80.2l-0.3,0.3l51.3,51.7
	l-16.1,16.1l-26.6-26.6c-3.3-3.3-9.5-3.6-13.1,0L11.4,483.3c-3.3,3.3-3.6,9.5,0,13.1l76,76c3.3,3.3,9.5,3.6,13.1,0L274,399.1
	c3.3-3.3,3.6-9.5,0-13.1l-26.6-26.6l16.1-16.1l66.9,66.9c3.3,3.3,9.5,3.6,13.1,0l25.3-25.3l12.1,12.1c-14.8,20.4-17.8,46.6-9,69.4
	c1.9,4.9,8.1,6.2,11.8,2.5l35.7-35.7l8.5,8.5c3.9,3.9,10.2,3.9,14.1,0c3.9-3.9,3.9-10.2,0-14.1l-8.5-8.5l35.7-35.7
	c3.7-3.7,2.3-9.9-2.5-11.8c-22.8-8.8-49-5.8-69.4,9L385,368.7l26-26c3.3-3.3,3.6-9.5,0-13.1l-67.2-67.2l16.1-16.1l26.6,26.6
	c3.3,3.3,9.5,3.6,13.1,0l172.8-172.8c3.3-3.3,3.6-9.5,0-13.1l-76-76C492.3,7.7,487.1,7.7,483.1,11z M151.7,467.6
	c4.3,4.3,4.3,11.5,0,15.7l-54.1,54.1c-4.3,4.3-11.5,4.3-15.7,0l-36.1-36.1c-4.3-4.3-4.3-11.5,0-15.7l54.1-54.1
	c4.3-4.3,11.5-4.3,15.7,0L151.7,467.6z M238.3,381.1c4.3,4.3,4.3,11.5,0,15.7l-54.1,54.1c-4.3,4.3-11.5,4.3-15.7,0l-36.1-36.1
	c-4.3-4.3-4.3-11.5,0-15.7l54.1-54.1c4.3-4.3,11.5-4.3,15.7,0L238.3,381.1z M452,167.3c4.3,4.3,4.3,11.5,0,15.7l-54.1,54.1
	c-4.3,4.3-11.5,4.3-15.7,0l-36.1-36.1c-4.3-4.3-4.3-11.5,0-15.7l54.1-54.1c4.3-4.3,11.5-4.3,15.7,0L452,167.3z M538.5,80.8
	c4.3,4.3,4.3,11.5,0,15.7l-54.1,54.1c-4.3,4.3-11.5,4.3-15.7,0l-36.1-36.1c-4.3-4.3-4.3-11.5,0-15.7l54.1-54.1
	c4.3-4.3,11.5-4.3,15.7,0L538.5,80.8z"
    />
    <path
      className="st0"
      d="M467.7,592.5c-10.4-10.5-6.4-25.5,6.9-29.7c21.7-6.9,40.4-18.5,56.4-34.6c15.2-15.4,26.1-33.3,33-53.8
	c1.4-4.1,4.2-8.3,7.5-11.1c5.3-4.5,13.5-4,18.9-0.1c6.6,4.7,9.2,11.9,6.9,19.6c-5.9,19.5-15.3,37.3-27.8,53.3
	c-21.8,27.9-49.4,47.8-83.1,59.1C479,597.7,472.5,597.1,467.7,592.5z"
    />
    <path
      className="st0"
      d="M506.4,505.1c-11.5,11.5-24.8,19.9-40.2,25c-6.2,2.1-12.2,2-17.6-2.3c-5.3-4.2-7.7-9.7-6.5-16.4
	c1.3-7.1,5.7-11.6,12.5-13.8c18.1-5.9,31.4-17.4,40.2-34.2c1.9-3.6,3.2-7.5,4.6-11.3c3.5-9.3,12.7-13.8,21.9-10.7
	c8.8,3,13.6,12.1,10.8,21.4C527.2,479.2,518.4,493.1,506.4,505.1z"
    />
  </svg>
);

const PrintIcon = (props: { className: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    className={props.className}
  >
    <defs>
      <style>{".a{fill:#FFFFFF;}"}</style>
    </defs>
    <path
      className="a"
      d="M9,2h6a2.89,2.89,0,0,1,2.121.879A2.89,2.89,0,0,1,18,5v5h2a3,3,0,0,1,3,3v4a3,3,0,0,1-3,3H17.828a2.954,2.954,0,0,1-1.086,1.441A2.912,2.912,0,0,1,15,22H9a2.9,2.9,0,0,1-1.742-.559A2.968,2.968,0,0,1,6.172,20H4a2.89,2.89,0,0,1-2.121-.879A2.89,2.89,0,0,1,1,17V13a2.89,2.89,0,0,1,.879-2.121A2.89,2.89,0,0,1,4,10H6V5a2.89,2.89,0,0,1,.879-2.121A2.89,2.89,0,0,1,9,2Zm6,16H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm5-6H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H6.172a2.954,2.954,0,0,1,1.086-1.441A2.912,2.912,0,0,1,9,16h6a2.9,2.9,0,0,1,1.742.559A2.968,2.968,0,0,1,17.828,18H20a1,1,0,0,0,1-1V13a1,1,0,0,0-1-1ZM15,4H9A1,1,0,0,0,8,5v5h8V5a1,1,0,0,0-1-1Z"
      transform="translate(-1 -2)"
    />
  </svg>
);

const CloseModalIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    width="11.466"
    height="11.466"
    viewBox="0 0 11.466 11.466"
  >
    <defs>
      <style>{`.a{fill:#cbcbcb;}`}</style>
    </defs>
    <path
      className="a"
      d="M16.563,8A5.733,5.733,0,1,0,22.3,13.733,5.733,5.733,0,0,0,16.563,8Zm2.209,8.679L16.563,14.47l-2.209,2.209-.737-.737,2.209-2.209-2.209-2.209.737-.737L16.563,13l2.209-2.209.737.737L17.3,13.733l2.209,2.209Z"
      transform="translate(-10.83 -8)"
    />
  </svg>
);
const HelpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <style type="text/css">{`.st0{fill:#21275D;}`}</style>
    <g transform="translate(-0.097 -0.097)">
      <path
        className="st0"
        d="M12.5,25A12.5,12.5,0,1,1,25,12.5,12.514,12.514,0,0,1,12.5,25Zm0-23.611A11.111,11.111,0,1,0,23.611,12.5,11.124,11.124,0,0,0,12.5,1.389Z"
        transform="translate(0.097 0.097)"
      />
      <path
        className="st0"
        d="M4.146,10.428a.765.765,0,0,1,.2-.566,1.019,1.019,0,0,1,.521-.272A4.534,4.534,0,0,0,7.134,8.527a2.536,2.536,0,0,0,.77-1.9,2.307,2.307,0,0,0-.668-1.664,2.538,2.538,0,0,0-1.913-.69A3,3,0,0,0,2.924,5.4q-.294.34-.521.656a.674.674,0,0,1-.566.317A.713.713,0,0,1,1.328,6.2a.651.651,0,0,1-.192-.5,1.348,1.348,0,0,1,.136-.611,3.937,3.937,0,0,1,.294-.5A5.049,5.049,0,0,1,3.06,3.332,4.485,4.485,0,0,1,5.323,2.8,4.287,4.287,0,0,1,8.379,3.841,3.609,3.609,0,0,1,9.488,6.6a4.041,4.041,0,0,1-.294,1.6,3.675,3.675,0,0,1-.815,1.2,5.035,5.035,0,0,1-1.222.871,7.76,7.76,0,0,1-1.539.589v2.648a.684.684,0,0,1-.747.724.689.689,0,0,1-.543-.2.754.754,0,0,1-.181-.521Zm.724,5.908a1.116,1.116,0,0,1,.826.306,1.332,1.332,0,0,1,0,1.7,1.116,1.116,0,0,1-.826.306,1.156,1.156,0,0,1-.837-.306,1.3,1.3,0,0,1,0-1.7A1.156,1.156,0,0,1,4.871,16.336Z"
        transform="translate(7.545 2.576)"
      />
    </g>
  </svg>
);

export {
  LICLogoIcon,
  SpaceLogoIcon,
  SearchIcon,
  LayersIcon,
  CloseIcon,
  SatelliteIcon,
  DownloadIcon,
  PrintIcon,
  CloseModalIcon,
  HelpIcon
};
