import { Configuration } from "../types/CommonTypes";

export async function getConfig(): Promise<Configuration> {
  return fetch("./constants.json")
    .then(response => response.json())
    .then(data => data)
    .catch(error => {
      throw Error(error);
    });
}
