import React from "react";
import { Button, Table, InputGroup, Input, InputGroupAddon } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDown,
  faSortAmountDownAlt
} from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";

import { EditedCover } from "../../types/CommonTypes";
import { SearchIcon, DownloadIcon } from "../Icons/Icons";
import { checkCoverRange } from "../../utils/mathHelpers";
import { COVER_LIMITATION } from "../../../../domain/constants";
import { CommonModal } from "../StaticContent/StaticContent";
import "./Sidebar.css";

interface ISidebarProps {
  paddocks: EditedCover[];
  csvData: string;
  csvFileName: string;
  showPredictedView: boolean;
  modalCount: number;
  increaseModalCount: any;
  updatePaddock: any;
}

interface ISidebarState {
  paddockData: EditedCover[];
  searchString: string;
  sortedDataAsc: boolean;
}

class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  constructor(props) {
    super(props);
    this.state = {
      paddockData: this.props.paddocks,
      searchString: "",
      sortedDataAsc: false
    };
  }

  private sortPaddock = () => {
    this.setState(state => {
      state.paddockData.sort(
        (leftPaddock: EditedCover, rightPaddock: EditedCover) =>
          state.sortedDataAsc
            ? leftPaddock.cover - rightPaddock.cover
            : rightPaddock.cover - leftPaddock.cover
      );
    });
  };

  private toggleSort = () => {
    this.setState(prevState => ({
      sortedDataAsc: !prevState.sortedDataAsc
    }));
    this.sortPaddock();
  };

  private updateCover = (paddockId: string, value: number) => {
    const paddockDataChanged = this.state.paddockData;
    const index = paddockDataChanged.findIndex(
      paddock => paddock.paddock_id === paddockId
    );
    if (index >= 0) {
      paddockDataChanged[index].cover = value;
      paddockDataChanged[index].isEdited = true;
      this.props.updatePaddock(paddockDataChanged);
    }
  };

  componentDidUpdate(prevProps, preState) {
    if (
      prevProps.paddocks !== this.props.paddocks ||
      prevProps.modalCount < this.props.modalCount ||
      preState.searchString !== this.state.searchString
    ) {
      this.setState({
        paddockData: this.props.paddocks
      });
      this.sortPaddock();
    }
  }

  renderTableData() {
    let data = this.state.paddockData;
    if (this.state.searchString) {
      data = data.filter(row =>
        row.paddock_name
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase())
      );
    }

    return data.map((paddock, index) => {
      const isEdited = paddock.isEdited;
      const roundedCover = paddock.cover;
      const coverText = roundedCover
        ? isEdited
          ? roundedCover
          : roundedCover < COVER_LIMITATION
          ? "No pasture"
          : roundedCover
        : "Excluded";
      return (
        <TableRowComponent
          paddock={paddock}
          cover={coverText}
          showPredictedView={this.props.showPredictedView}
          key={index}
          modalCount={this.props.modalCount}
          increaseModalCount={this.props.increaseModalCount}
          updateCover={this.updateCover}
        />
      );
    });
  }

  render() {
    const { searchString, paddockData, sortedDataAsc } = this.state;

    return (
      <div>
        <InputGroup className="paddock-tab-name">
          <InputGroupAddon addonType="append" className="download-buttons">
            <DownloadCSVButton
              className="download-button"
              csvData={this.props.csvData}
              csvFileName={this.props.csvFileName}
            />
          </InputGroupAddon>
          <SearchIcon className="search-icon" />
          <Input
            placeholder="Enter a paddock name..."
            value={searchString}
            onChange={event =>
              this.setState({ searchString: event.target.value })
            }
          />
          <InputGroupAddon addonType="append">
            <Button
              className="search-button"
              onClick={() => {
                this.sortPaddock();
              }}
            >
              Search
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <div className="table-responsive content-container">
          <Table striped>
            <thead>
              <tr>
                <th style={{ borderRight: "1px solid lightgray" }}>
                  Paddock Name
                </th>
                <th>
                  Kg DM/ha
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortedDataAsc ? faSortAmountDownAlt : faSortAmountDown
                    }
                    onClick={this.toggleSort}
                  />
                </th>
                {this.props.showPredictedView && (
                  <th style={{ textAlign: "center" }}>Mark as ..</th>
                )}
              </tr>
            </thead>
            <tbody>
              {paddockData.length === 0 && searchString.length !== 0 ? (
                <tr>
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    Paddock name not found
                  </td>
                </tr>
              ) : (
                this.renderTableData()
              )}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

// Table component for rendering each table row
interface ITableRowProps {
  paddock: EditedCover;
  cover: any;
  showPredictedView: boolean;
  modalCount: number;
  increaseModalCount: any;
  updateCover: any;
}

interface ITableRowState {
  showEditableField: boolean;
  updatedCover: any;
  errorMessage: string;
  showModal: boolean;
}

class TableRowComponent extends React.Component<
  ITableRowProps,
  ITableRowState
> {
  constructor(props) {
    super(props);
    this.state = {
      showEditableField: false,
      updatedCover: this.props.cover,
      errorMessage: "",
      showModal: false
    };
    this.updateGrazedPaddock = this.updateGrazedPaddock.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  private handleChange(e) {
    this.setState({ errorMessage: "" });
    if (e.target.validity.valid) {
      this.setState({ updatedCover: e.target.value });
    } else {
      this.setState({ errorMessage: "Please insert numbers only" });
    }
  }

  private toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  // Function to update the cover(grazing functionality)
  private updateGrazedPaddock() {
    this.setState({
      showEditableField: true,
      errorMessage: ""
    });
    if (this.state.showEditableField) {
      if (checkCoverRange(this.state.updatedCover)) {
        this.setState({
          showEditableField: false
        });
        if (this.state.updatedCover !== this.props.cover) {
          this.setState({
            showModal: true
          });
          this.props.updateCover(
            this.props.paddock.paddock_id,
            Number(this.state.updatedCover)
          );
          this.props.increaseModalCount();
        }
      } else {
        this.setState({ errorMessage: "Please insert within 1 and 15000" });
      }
    }
  }

  componentDidUpdate(preProps) {
    if (preProps.paddock !== this.props.paddock) {
      this.setState({ updatedCover: this.props.cover });
    }
  }

  render() {
    const {
      showEditableField,
      updatedCover,
      errorMessage,
      showModal
    } = this.state;
    const { modalCount } = this.props;
    const { id, paddock_name, isPredicted, isEdited } = this.props.paddock;
    return (
      <>
        {modalCount === 1 ? (
          <CommonModal
            className="grazing-modal"
            modal={showModal}
            toggle={this.toggleModal}
            type="grazing"
            predicted={false}
          />
        ) : null}

        <tr
          key={id}
          id={isEdited ? "updated-paddock-row" : ""}
          className={isPredicted ? "predictedCover" : "satelliteCover"}
        >
          <td>{paddock_name}</td>
          <td
            className={
              isPredicted
                ? "predictedCover cover-data"
                : "satelliteCover cover-data"
            }
          >
            {showEditableField ? (
              <div>
                <Input
                  type="tel"
                  value={updatedCover}
                  onChange={this.handleChange}
                  pattern="^[1-9][0-9]*$"
                  className={errorMessage && "validation-error"}
                />
                {errorMessage && (
                  <span className="error-tooltip">{errorMessage}</span>
                )}
              </div>
            ) : (
              updatedCover
            )}
          </td>
          {this.props.showPredictedView && (
            <td>
              <Button
                className="grazed-button"
                onClick={this.updateGrazedPaddock}
              >
                {showEditableField ? "UPDATE" : "GRAZED"}
              </Button>
            </td>
          )}
        </tr>
      </>
    );
  }
}

interface IDownloadCSVButtonProps {
  csvData: string;
  csvFileName: string;
  className: string;
}

interface IDownloadCSVButtonState {
  disableCSVButton: boolean;
}

class DownloadCSVButton extends React.Component<
  IDownloadCSVButtonProps,
  IDownloadCSVButtonState
> {
  constructor(props) {
    super(props);
    this.state = {
      disableCSVButton: true
    };
  }

  componentDidMount() {
    // Sidebar in tab panel get data when it first loads
    if (this.props.csvData) {
      this.setState({
        disableCSVButton: false
      });
    }
  }

  componentDidUpdate(previousProps) {
    // Sidebar in front page get data when page re-renders
    if (previousProps.csvData !== this.props.csvData) {
      this.setState({
        disableCSVButton: false
      });
    }
  }

  render() {
    return (
      <>
        <Button
          outline
          className={this.props.className}
          disabled={this.state.disableCSVButton}
        >
          {this.state.disableCSVButton ? (
            <div>
              <DownloadIcon className="download-icon" />
              <span style={{ color: "#FFF" }}>CSV</span>
            </div>
          ) : (
            <CSVLink
              data={this.props.csvData}
              filename={this.props.csvFileName}
            >
              <DownloadIcon className="download-icon" />
              <span style={{ color: "#FFF" }}>CSV</span>
            </CSVLink>
          )}
        </Button>
      </>
    );
  }
}

export { Sidebar, DownloadCSVButton };
