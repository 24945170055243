import React, { Component } from "react";
import * as d3 from "d3";

import "./index.css";
import { Cover, EditedCover } from "../../types/CommonTypes";
import { GRAZING_UNIT } from "../../constants";
import { COVER_LIMITATION, TICKS } from "../../../../domain/constants";

interface FeedWedgeBarChartProps {
  preGrazing: number;
  postGrazing: number;
  showTarget: boolean;
  feedWedge: EditedCover[];
  maxCover: number;
}

interface FeedWedgeBarChartState {
  containerWidth: number;
  containerHeight: number;
  containerOverviewHeight: number;
}
export default class FeedWedgeBarChart extends Component<
  FeedWedgeBarChartProps,
  FeedWedgeBarChartState
> {
  private chartContainer: any;
  private sliderContainer: any;
  private svgContainer: any;
  private svgPrintContainer: any;
  private feedWedgeData: EditedCover[];
  private labelHeight: number;
  private margin = { top: 30, right: 10, bottom: 50, left: 90 };
  private marginOverview = { top: 90, right: 10, bottom: 20, left: 90 };
  // Set the default height of diagram as 450, it is an empirical value;
  private coordinateheight = 450;
  // Set the default bar width as 35, it is an empirical value and used to determine maximum number of bars in one page;
  private barWidth = 35;

  // Use 40 as height of the slider
  private selectorHeight = 40;

  constructor(props: Readonly<FeedWedgeBarChartProps>) {
    super(props);
    const sortedPaddock = this.props.feedWedge.sort(this.sortPaddock);
    const filterData = sortedPaddock.filter(this.paddockFilter);
    this.feedWedgeData = filterData.map(
      value =>
        ({
          paddock_name: value.paddock_name,
          cover: value.cover,
          id: value.id,
          isPredicted: value.isPredicted,
          isEdited: value.isEdited
        } as EditedCover)
    );

    // Set the minimum xAxis text length as 5, it is an empirical value，smaller length will make text overlap
    const minTextLength = 5;
    // This length is used to calculate the width of bar, maximum value will make sure the longest text could show properly
    const textLength: number = Math.max(
      d3.max(
        this.feedWedgeData.map(cover => {
          return cover.paddock_name.length;
        })
      ) || minTextLength,
      minTextLength
    );
    // Set the label height as the 7 times of text length, it is an empirical value;
    this.labelHeight = textLength * 7;

    // Initialize default chart container width and height
    this.state = {
      containerWidth: 940,
      containerHeight:
        this.coordinateheight +
        this.margin.top +
        this.margin.bottom +
        this.selectorHeight +
        this.labelHeight,
      containerOverviewHeight: 140
    };
  }

  sortPaddock = (leftPaddock: EditedCover, rightPaddock: EditedCover) => {
    return rightPaddock.cover - leftPaddock.cover;
  };

  paddockFilter = (paddock: EditedCover) => {
    return (
      (paddock.cover && paddock.cover >= COVER_LIMITATION) || paddock.isEdited
    );
  };

  clearD3 = () => {
    const svgInstance = d3.select(this.svgContainer);
    const svgPrintInstance = d3.select(this.svgPrintContainer);
    if (svgInstance) {
      svgInstance.selectAll("*").remove();
    }
    if (svgPrintInstance) {
      svgPrintInstance.selectAll("*").remove();
    }
  };

  createBarChart = () => {
    const sortedPaddock = this.props.feedWedge.sort(this.sortPaddock);
    const filterData = sortedPaddock.filter(this.paddockFilter);
    this.feedWedgeData = filterData.map(
      value =>
        ({
          paddock_name: value.paddock_name,
          cover: value.cover,
          id: value.id,
          isPredicted: value.isPredicted,
          isEdited: value.isEdited
        } as EditedCover)
    );
    const svgDimensions = {
      originWidth: this.state.containerWidth,
      originHeight: this.state.containerHeight,
      originOverviewHeight: this.state.containerOverviewHeight
    };

    // Set width and height of diagram
    const width =
      svgDimensions.originWidth - this.margin.left - this.margin.right;
    const height = this.coordinateheight;

    // Set height of scroll bar
    const heightOverview =
      svgDimensions.originOverviewHeight -
      this.marginOverview.top -
      this.marginOverview.bottom;

    // Set number of bars display in one page
    const numBars = Math.min(
      Math.round(width / this.barWidth),
      this.feedWedgeData.length
    );

    // Determine whether to show scroll bar
    const isScrollDisplayed = this.barWidth * this.feedWedgeData.length > width;

    // Create x and y scale instance
    const xscale = d3
      .scaleBand()
      .domain(
        this.feedWedgeData.slice(0, numBars).map((feedWedge: Cover) => {
          return feedWedge.paddock_name;
        })
      )
      .rangeRound([0, width])
      .padding(0.2);

    const yscale = d3
      .scaleLinear()
      .domain([0, this.props.maxCover])
      .range([height, 0]);

    const tickStep = this.props.maxCover / (TICKS - 1);
    const xAxis = d3.axisBottom(xscale);
    const yAxis = d3
      .axisLeft(yscale)
      .ticks(TICKS)
      .tickValues(d3.range(0, this.props.maxCover + tickStep, tickStep));

    // Generate diagram container
    const svg = d3.select(this.svgContainer);
    svg.attr("height", svgDimensions.originHeight);
    const diagram = svg
      .append("g")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`);

    // Generate x axis, store this component for later use
    const xAxisD3 = diagram
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis);

    // Generate y axis
    diagram
      .append("g")
      .attr("class", "y axis")
      .call(yAxis);

    // Generate label for y axis
    diagram
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - this.margin.left)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .attr("class", "yAxis-text")
      .text(`Value (${GRAZING_UNIT})`);

    // Generate bars
    const bars = diagram.append("g");
    bars
      .selectAll("rect")
      .data(this.feedWedgeData.slice(0, numBars), (feedWedge: any) => {
        return feedWedge.paddock_name;
      })
      .enter()
      .append("rect")
      .attr("class", (feedWedge: EditedCover) => {
        return feedWedge.isEdited
          ? "editedBar"
          : feedWedge.isPredicted
          ? "predictedBar"
          : "satelliteBar";
      })
      .attr("x", (feedWedge: EditedCover) => {
        return xscale(feedWedge.paddock_name) as any;
      })
      .attr("y", (feedWedge: EditedCover) => {
        return yscale(feedWedge.cover);
      })
      .attr("width", xscale.bandwidth())
      .attr("height", (feedWedge: EditedCover) => {
        return height - yscale(feedWedge.cover);
      });

    // Make x axis label vertical
    xAxisD3
      .selectAll("text")
      .attr("y", 0)
      .attr("x", 9)
      .attr("dy", ".35em")
      .attr("transform", "rotate(90)")
      .style("text-anchor", "start");

    // Create container for grazing line
    const grazingLineContainer = diagram
      .append("g")
      .attr("class", "grazing-line");
    // Setup increase step for grazing line
    const yIncreaseStep: number =
      this.feedWedgeData.length > 0
        ? (this.props.preGrazing - this.props.postGrazing) /
          this.feedWedgeData.length
        : 0;

    if (this.feedWedgeData.length > 0) {
      // Calculate start and end coordinate value for grazing line
      const xGrazingStart = xscale(this.feedWedgeData[0].paddock_name);
      const xGrazingEnd =
        (xscale(this.feedWedgeData[numBars - 1].paddock_name) || 0) +
        xscale.bandwidth();
      const yGrazingStart = yscale(this.props.preGrazing);
      const yGrazingEnd =
        numBars < this.feedWedgeData.length
          ? yscale(this.props.preGrazing - numBars * yIncreaseStep)
          : yscale(this.props.postGrazing);
      // Create grazing line
      if (this.props.showTarget && xGrazingStart && xGrazingEnd) {
        grazingLineContainer
          .append("line")
          .style("stroke", "orange")
          .style("stroke-width", "2px")
          .attr("x1", xGrazingStart)
          .attr("y1", yGrazingStart)
          .attr("x2", xGrazingEnd)
          .attr("y2", yGrazingEnd);
      }
    }
    if (isScrollDisplayed) {
      // Calculate x and y axis of sub bar
      const xOverview = d3
        .scaleBand()
        .domain(
          this.feedWedgeData.map((feedWedge: any) => {
            return feedWedge.paddock_name;
          })
        )
        .rangeRound([0, width])
        .padding(0.2);
      const yOverview = d3.scaleLinear().range([heightOverview, 0]);
      yOverview.domain(yscale.domain());

      const subBars = diagram.selectAll(".subBar").data(this.feedWedgeData);

      subBars
        .enter()
        .append("rect")
        .classed("subBar", true)
        .attr(
          "height",
          (feedWedge: any) => heightOverview - yOverview(feedWedge.cover)
        )
        .attr("width", () => xOverview.bandwidth())
        .attr("x", (feedWedge: any) => xOverview(feedWedge.paddock_name) as any)
        .attr(
          "y",
          (feedWedge: any) =>
            height +
            heightOverview +
            yOverview(feedWedge.cover) +
            this.labelHeight
        );

      const displayed = d3
        .scaleQuantize()
        .domain([0, width])
        .range(d3.range(this.feedWedgeData.length));

      const display = () => {
        const sliderBox: any = this.sliderContainer.node();
        const sliderXAxis = parseInt(d3.select(sliderBox).attr("x"), 10);
        const newSliderXAxis = sliderXAxis + d3.event.dx;
        const boxWidth = parseInt(d3.select(sliderBox).attr("width"), 10);
        const dataStart = displayed(sliderXAxis);
        const newDataStart = displayed(newSliderXAxis);

        if (newSliderXAxis < 0 || newSliderXAxis + boxWidth > width) return;

        d3.select(sliderBox).attr("x", newSliderXAxis);

        if (dataStart === newDataStart) return;

        const newDataEnd = newDataStart + numBars + 1;
        const newData = this.feedWedgeData.slice(newDataStart, newDataEnd);

        xscale.domain(
          newData.map(feedWedge => {
            return feedWedge.paddock_name;
          })
        );
        diagram.select(".x.axis").call(xAxis as any);

        // Recalculate start and end coordinate value for grazing line
        const newXGrazingStart = xscale(newData[0].paddock_name);
        const newXGrazingEnd =
          (xscale(newData[newData.length - 1].paddock_name) || 0) +
          xscale.bandwidth();
        const newYGrazingStart = yscale(
          this.props.preGrazing - newDataStart * yIncreaseStep
        );
        const newYGrazingEnd =
          newDataEnd < this.feedWedgeData.length
            ? yscale(this.props.preGrazing - newDataEnd * yIncreaseStep)
            : yscale(this.props.postGrazing);

        // Show grazing line
        if (this.props.showTarget && newXGrazingStart && newXGrazingEnd) {
          // Remove the old one and create new line
          grazingLineContainer.selectAll("*").remove();

          grazingLineContainer
            .append("line")
            .style("stroke", "orange")
            .style("stroke-width", "2px")
            .attr("x1", newXGrazingStart)
            .attr("y1", newYGrazingStart)
            .attr("x2", newXGrazingEnd)
            .attr("y2", newYGrazingEnd);
        }

        const rects = bars.selectAll("rect").data(newData, (feedWedge: any) => {
          return feedWedge.paddock_name;
        });

        rects.attr("x", (feedWedge: any) => {
          return xscale(feedWedge.paddock_name) as any;
        });

        rects
          .enter()
          .append("rect")
          .attr("class", (feedWedge: EditedCover) => {
            return feedWedge.isEdited
              ? "editedBar"
              : feedWedge.isPredicted
              ? "predictedBar"
              : "satelliteBar";
          })
          .attr("x", (feedWedge: any) => {
            return xscale(feedWedge.paddock_name) as any;
          })
          .attr("y", feedWedge => {
            return yscale(feedWedge.cover);
          })
          .attr("width", xscale.bandwidth())
          .attr("height", (feedWedge: any) => {
            return (height - yscale(feedWedge.cover)) as any;
          });

        // Display x axis label vertical when scrolling the bar chart
        xAxisD3
          .selectAll("text")
          .attr("y", 0)
          .attr("x", 9)
          .attr("dy", ".35em")
          .attr("transform", "rotate(90)")
          .style("text-anchor", "start");

        rects.exit().remove();
      };

      const container = diagram.append("rect");
      this.sliderContainer = container;
      container
        .attr("transform", `translate(0, ${height + 20})`)
        .attr("class", "mover")
        .attr("x", 0)
        .attr("y", 10 + this.labelHeight)
        .attr("height", this.selectorHeight)
        .attr(
          "width",
          Math.round((numBars * width) / this.feedWedgeData.length)
        )
        .attr("pointer-events", "all")
        .attr("cursor", "ew-resize")
        .call(d3.drag().on("drag", display) as any);
    }
  };

  componentDidMount() {
    this.fitParentContainer();
    this.createBarChart();
    window.addEventListener("resize", this.fitParentContainer);
  }

  componentDidUpdate() {
    this.clearD3();
    this.fitParentContainer();
    this.createBarChart();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.fitParentContainer);
  }

  // Change width of diagram container when page resize
  fitParentContainer = () => {
    const { containerWidth } = this.state;
    const currentContainerWidth = this.chartContainer.getBoundingClientRect()
      .width;

    let shouldResize = false;
    if (currentContainerWidth) {
      shouldResize = containerWidth !== currentContainerWidth;
    }

    if (shouldResize) {
      this.setState({
        containerWidth: currentContainerWidth
      });
    }
  };

  render() {
    return (
      <div
        ref={el1 => {
          this.chartContainer = el1;
        }}
        id="Responsive-wrapper"
      >
        <div id="chart" className="normal-chart">
          <svg
            ref={el => {
              this.svgContainer = el;
            }}
            width={Math.max(this.state.containerWidth, 300)}
            height={this.state.containerHeight}
          />
        </div>
      </div>
    );
  }
}
