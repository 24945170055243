import * as React from "react";

import { SatelliteIcon } from "../Icons/Icons";
import "./LoadingIcon.css";

const LoadingIcon = (props: { className?: string }) => {
  return (
    <div className={props.className}>
      <div className="earth">
        <div className="satellite">
          <SatelliteIcon className="satellite-body" />
        </div>
      </div>
      <div className="loading-text">Preparing your pasture report...</div>
    </div>
  );
};

export { LoadingIcon };
