import React from "react";
import { Row, Col, Button } from "reactstrap";

import { LayersIcon, HelpIcon } from "../Icons/Icons";
import MapLayerSwitch from "../MapLayerSwitch";
import PastureAndCloudMap from "../PastureAndCloudMap";
import { MapType } from "../../types/CommonTypes";
import "./CoverMap.css";
import { ReportType } from "../../../../domain/models/ReportType";

interface CoverMapProperty {
  pastureImage: string;
  cloudShadow: string;
  bbox: number[];
  activeTab?: string;
  changeMapType: Function;
  mapType: MapType;
  pdfUrl: string;
  reportType: ReportType;
  sessionCode: string;
  imageId: string;
}

interface CoverMapState {
  isLayerShow: boolean;
}

class CoverMap extends React.Component<CoverMapProperty, CoverMapState> {
  constructor(props) {
    super(props);
    this.state = {
      isLayerShow: false
    };
  }

  render() {
    const {
      pastureImage,
      cloudShadow,
      bbox,
      activeTab,
      changeMapType,
      mapType,
      pdfUrl,
      reportType,
      sessionCode,
      imageId
    } = this.props;

    const { isLayerShow } = this.state;

    return (
      <div>
        <Row>
          <Col className="pasture_center" lg={12}>
            <PastureAndCloudMap
              pastureImage={pastureImage}
              cloudShadowImage={cloudShadow}
              bbox={bbox}
              mapTypeSelected={mapType}
              activeTab={activeTab}
              sessionCode={sessionCode}
              imageId={imageId}
            />
          </Col>
          <div className="layers-icon">
            <Button
              color="secondary"
              onClick={() => {
                this.setState({
                  isLayerShow: !isLayerShow
                });
              }}
            >
              <LayersIcon />
            </Button>
          </div>
          <div className="help-icon">
            <Button color="secondary" href="./Guide to SPACE.pdf" download>
              <HelpIcon />
            </Button>
          </div>
          <MapLayerSwitch
            isLayerMenuShowed={isLayerShow}
            mapType={mapType}
            onChangeLayerState={this.changeLayerState}
            onChangeMapType={changeMapType}
            pdfUrl={pdfUrl}
            reportType={reportType}
          />
        </Row>
      </div>
    );
  }

  private changeLayerState = (isLayerShow: boolean) => {
    this.setState({
      isLayerShow
    });
  };
}

export { CoverMap };
